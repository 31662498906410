import { useEffect, useState } from "react";
import { useAppContext } from "hooks/app";
import { Modals } from "constants/modals";
import ModalLayout from "components/ModalLayout/ModalLayout";
import { useLocation } from "react-router-dom";
import { useUser } from "hooks/user";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import PhoneVerification from "./PhoneVerification/PhoneVerification";
import { isValidPhoneNumber } from "react-phone-number-input";
import { usePhoneVerify } from "hooks/user";
import toast from "react-hot-toast";

export const BarePhoneVerificationComponent = () => {
  const { openModal } = useAppContext();
  const [showOtp, setShowOtp] = useState(false);
  const { pathname } = useLocation();
  const { user, isLoading } = useUser();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const { sendWhatsappOtp } = usePhoneVerify();

  const [isRequestLoading, setIsRequestLoading] = useState(false)
  useEffect(() => {
    if (isLoading) return;
    if (!user?.phoneNumber && pathname.includes("user")) {
      openModal?.(Modals.PHONE_POPUP);
    }
  }, [user]);

  const handleSendOtp = async () => {
    setIsPhoneValid(isValidPhoneNumber(phoneNumber));

    if (!isValidPhoneNumber(phoneNumber)) return;
    setIsRequestLoading(true)
    await sendWhatsappOtp({ id: user?.id, phone: phoneNumber }).then((data) => {
      if(data.success) {
        setShowOtp(true);
      } else {
        toast.error(data.message)
      }
      setIsRequestLoading(false)
    });
  };

  const handleNumberChange = (number: string) => {
    isValidPhoneNumber(number) && setIsPhoneValid(true);

    setPhoneNumber(number);
  };

  return (
    <div className="flex flex-col items-start justify-start min-h-[400px] gap-4">
        {!showOtp ? (
          <>
            <div>
              <h3 className="text-xl font-bold">Verify your WhatsApp</h3>
              <p>Insert WhatsApp number to verify</p>
            </div>
            <div className="flex">
              <PhoneInput
                defaultCountry="pk"
                value={phoneNumber}
                onChange={(phoneNumber) => handleNumberChange(phoneNumber)}
                style={isPhoneValid ? {
                } : { border: "1px solid red", borderRadius: '5px' }}
                inputStyle={{
                }}
              />
              {!isPhoneValid && (
                <p style={{ color: "red", fontSize: '12px' }}>Enter a valid number</p>
              )}
            </div>
              
            <button className="btnPrimary" disabled={isRequestLoading} onClick={handleSendOtp}>
              Verify Now
            </button>
          </>
        ) : (
          <PhoneVerification
            onBack={() => setShowOtp(false)}
            phone={phoneNumber}
          />
        )}
      </div>
  )
}

const PhonePopup = () => {
  

  return (
    <ModalLayout modal={Modals.PHONE_POPUP}>
      <BarePhoneVerificationComponent />    
    </ModalLayout>
  );
};
export default PhonePopup;
