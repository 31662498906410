import React from "react"
import { Route, Navigate } from "react-router-dom"
import UserLayout from "./Layouts/UserLayout";
import UserPageLoader from "./UserPageLoader";
import ProductDiscovery from "pages/UserDashboard/ProductDiscovery/ProductDiscovery";
import StoreDiscovery from "pages/UserDashboard/StoreDiscovery/StoreDiscovery";
import { ROLES } from "enum/roles";

const Dashboard = React.lazy(() => import("pages/UserDashboard//Dashboard/Dashboard"))
const Market = React.lazy(() => import("pages/UserDashboard//Market/Market"))
const Billing = React.lazy(() => import("pages/UserDashboard/Billing/Billing"))
const TrackingList = React.lazy(() => import("pages/UserDashboard//Tracking/TrackingList/TrackingList"))
const StoreLibrary = React.lazy(() => import("pages/UserDashboard//Tracking/StoreLibrary/StoreLibrary"))
const AdminProfile = React.lazy(() => import("pages/SuperAdmin/AdminProfille/Profile"))
const Supplier = React.lazy(() => import("pages/UserDashboard/Collection/Supplier/Supplier"))
const Product = React.lazy(() => import("pages/UserDashboard/Collection/Product/Product"))
const PaymentHistoryI = React.lazy(() => import("pages/UserDashboard/PaymentHistory/PaymentHistory"))
const PaymentSuccess = React.lazy(() => import("pages/PaymentSuccess/PaymentSuccess"))
const Onboarding = React.lazy(() => import("pages/Auth/Onboarding/Onboarding"))

const UserRoutes = (userId?: number, onboarding?: string, isLoadingUser?: boolean, userRole?: string) => {

    if (isLoadingUser) return [];

    if(!userId) return [
            <Route key={"user/*"} path="user/*" element={<Navigate to="/signin" />} />,
            <Route key={"thank-you"} path="thank-you" element={<Navigate to="/signin" />} />,
    ]
    if(!onboarding || onboarding !== "complete" && userRole === ROLES.CUSTOMER) return [
        <Route key={"user/*"} path="user/*" element={<Navigate to="/onboarding" />} />,
        <Route key={"thank-you2"} path="thank-you" element={<Navigate to="/onboarding" />} />,
        <Route path="/onboarding" element={<React.Suspense fallback={<UserPageLoader />}><Onboarding /></React.Suspense>} />
    ]
    // if(!status) return [
    //         <Route key={"user/*"} path="user/*" element={<Navigate to="/verify" />} />,
    //         <Route key={"thank-you2"} path="thank-you" element={<Navigate to="/verify" />} />,
    // ]
    return [
            <Route key={"user"} path="user" element={<React.Suspense ><UserLayout /></React.Suspense>} >
                <Route key={"user/dashboard"} path="dashboard" element={<React.Suspense fallback={<UserPageLoader />}><Dashboard /></React.Suspense>} />
                <Route key={"user/market"} path="market" element={<React.Suspense fallback={<UserPageLoader />}><Market /></React.Suspense>} />
                <Route key={"user/profile"} path="profile" element={<React.Suspense fallback={<UserPageLoader />}><AdminProfile /></React.Suspense>} />
                <Route key={"user/collection-product"} path="collection-product" element={<React.Suspense fallback={<UserPageLoader />}><Product /></React.Suspense>} />
                <Route key={"user/collection-product/:id"} path="collection-product/:id" element={<React.Suspense fallback={<UserPageLoader />}><Product /></React.Suspense>} />
                <Route key={"user/collection-suppliers"} path="collection-suppliers" element={<React.Suspense fallback={<UserPageLoader />}><Supplier /></React.Suspense>} />
                <Route key={"user/tracking/store-library"} path="tracking/store-library" element={<React.Suspense fallback={<UserPageLoader />}><StoreLibrary /></React.Suspense>} />
                <Route key={"user/tracking/tracking-list"} path="tracking/tracking-list" element={<React.Suspense fallback={<UserPageLoader />}><TrackingList /></React.Suspense>} />
                <Route key={"user/payments"} path="payments" element={<React.Suspense fallback={<UserPageLoader />}><PaymentHistoryI /></React.Suspense>} />
                <Route key={"user/billing"} path="billing" element={<React.Suspense fallback={<UserPageLoader />}><Billing /></React.Suspense>} />
                <Route key={"user/product-discovery"} path="product-discovery" element={<React.Suspense fallback={<UserPageLoader />}><ProductDiscovery /></React.Suspense>} />
                <Route key={"user/store-discovery"} path="store-discovery" element={<React.Suspense fallback={<UserPageLoader />}><StoreDiscovery /></React.Suspense>} />

            </Route>,
        <Route key={"payment-success-thank-you"} path="thank-you" element={<React.Suspense fallback={<UserPageLoader />}><PaymentSuccess /></React.Suspense>} />,
    ]
};

export default UserRoutes
 