import { useState } from "react"

import { useUpdateProfile } from "hooks/user"
import AuthLayout from "layouts/AuthLayout"

const Marketplace = () => {
    const { updateProfile, isLoading } = useUpdateProfile()

    const options = [
        { label: "Pakistan", value: "pk" },
        { label: "Bangladesh", value: "bg" },
        { label: "Nepal", value: "np" },
        { label: "Sri Lanka", value: "sl" },
        { label: "Malysia", value: "my" },
        { label: "Indonesia", value: "id" },
        { label: "Singapur", value: "sg" },
        { label: "Thailand", value: "th" },
        { label: "The Philippines", value: "ph" },
        { label: "Vietnam", value: "vn" },
    ]

    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const handleSelect = (option: string) => {
        setSelectedOption(option);
    };

    const handleNext= () => {
        if (selectedOption)
            updateProfile({ 
                marketplace: selectedOption,
                onboarding: "referrer"
            })
    }

    return (
        <AuthLayout
            title="Referrer - Sellerdash"
            page="Marketplace"
            pageWidth="30%"
            >
            <div className="flex flex-col items-center justify-center">
                <div className="text-center mb-8">
                    <h1 className="text-2xl md:text-2xl font-bold text-gray-800">
                        Choose your marketplace
                    </h1>
                    <p className="text-gray-600 mt-2">
                    Help us to show you data as per your marketplace.
                    </p>
                </div>

                <div className="flex flex-wrap justify-center gap-4 mb-8">
                    {options.map((option) => (
                    <button
                        key={option.label}
                        onClick={() => handleSelect(option.value)}
                        className={`px-6 py-3 rounded-lg font-medium text-sm border transition-all duration-200 shadow-md
                        ${
                            selectedOption === option.value
                            ? "bg-primary text-white border-primary"
                            : "bg-white text-gray-700 border-gray-300 hover:bg-gray-100"
                        }`}
                    >
                        {option.label}
                    </button>
                    ))}
                </div>

                <button
                    onClick={handleNext}
                    className="px-8 py-3 bg-primary text-white rounded-lg font-medium text-sm shadow-md hover:bg-secondary cursor-pointer transition-all duration-200"
                    disabled={!selectedOption}
                >
                    Next
                </button>
                </div>
        </AuthLayout>
    )
}

export default Marketplace
