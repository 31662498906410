import { Flex, Text } from "@chakra-ui/react";
import * as styles from "./style";
import FormButton from "components/Form/Button/FormButton";
import Form from "components/Form/Form";
import { useRecoveryEmail, useUser, useVerify } from "hooks/user";

import { IoIosArrowRoundBack } from "react-icons/io";
import { useState } from "react";
import OtpInput from "react-otp-input";
import AuthLayout from "layouts/AuthLayout";
import { removeToken } from "utils/token";
import { useQueryClient } from "@tanstack/react-query";
import { useMediaQuery } from '@chakra-ui/react'

const Verify = () => {
  const { user } = useUser();
  const { verify, resendCode, isVerifying } = useVerify();
  const [otp, setOtp] = useState();
  const client = useQueryClient()
  const { email } = useRecoveryEmail();
  const [ isLargerThan470 ] = useMediaQuery('(min-width: 470px)')

  const handleVerify = async () => {
    try {
      if (email || user?.email) {
        await verify({ code: otp as any, email: email ? email: user!.email });
      }
    }
    catch(err) {}
  };

  const handleResend = async () => {
    try {
      if (email || user?.email) {
        await resendCode({ email: email ? email: user!.email });
      }
    }
    catch (err) {
    }
  };

  const handleChange = (otp: any) => setOtp(otp);

  const handleBack = () => {
    removeToken()
    client.setQueryData(["refresh"], null);
  }

  return (
    <AuthLayout
      title="Verify Email - Sellerdash"
      page="Verify Code"
      pageWidth="30%"
    >
      <Flex flexDir="column" align="flex-start" justify="flex-start">
        <Text {...styles.WelcomeText}>Almost Done!</Text>
        <Flex {...styles.GapWorries}>
          <Text {...styles.Worries}>
            We have sent an OTP code at <Text as="span" {...styles.WorriesEmail}>{user?.email}
            </Text>
          </Text>
        </Flex>
      </Flex>
      <Flex sx={styles.FormWrapper} w={isLargerThan470 ? '370px' : '90%'} >
        <Form
          initialValues={{
            code: "",
          }}
          onSubmit={handleVerify}
        >
          <Flex sx={styles.TextInputWrapper}>
            <Text sx={styles.InputLabel}>Enter Code</Text>
            <OtpInput
              onChange={handleChange}
              numInputs={6}
              value={otp}
              inputStyle="otp-input"
              focusStyle="otp-input-focus"
            />
          </Flex>
          <Flex sx={styles.SubmitWrapper}>
            <FormButton
              disabled={isVerifying}
              isLoading={isVerifying}
              h="50px"
              w="100%"
              text="Verify Code"
              variant="primary"
              borderRadius="6px"
              fontSize="16px"
              fontWeight="500"
            />
            <Flex {...styles.Resend} fontSize={isLargerThan470 ? "15px" : "11px"}>
              <Text>Didn't receive the email?</Text>
              <Text
                color="#185ADB"
                cursor="pointer"
                onClick={handleResend}
              >
                Click to resend
              </Text>
            </Flex>
            <Flex {...styles.BackWrapper}>
              <IoIosArrowRoundBack {...styles.IconBack} />
              <Text {...styles.BackText} onClick={handleBack}>
                Back to login
              </Text>
            </Flex>
          </Flex>
        </Form>
      </Flex>
    </AuthLayout>
  );
};
export default Verify;
