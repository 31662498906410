import ModalWrapper from "components/ModalsWrapper";
import UserRoutes from "components/UserRoutes";
import AdminRoutes from "components/AdminRoutes";
import NoAuthRoutes from "components/NoAuthRoutes";
import PublicRoutes from "components/PublicRoutes";
import NotVerifiedRoutes from "components/NotVerifiedRoutes";
import { Toaster } from "react-hot-toast"
import { Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import FullScreenLoader from "components/FullScreenLoader";
import Permissionpage from "components/Permissionpage/Permissionpage";
import { AnimatePresence } from "framer-motion";
import { useUser } from "hooks/user";
// import analytics from "analytics"
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';
import { useAppContext } from "hooks/app";

function App() {
  const { user, isLoading } = useUser();
  const { appState, setAppState } = useAppContext()

  useEffect(() => {
      // analytics(window, document, 'script', 'dataLayer', process.env.REACT_APP_GOOGLE_TAG_ID || "");
      TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_ID as string })
      if(!appState?.isMetaPixel){
        ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID as string) // Initialize Pixel
        ReactPixel.pageView(); // Track page views
        setAppState?.(prev => ({ ...prev, isMetaPixel: true }))
      }
        
  },[])

  return (
    <>
      <Toaster /> 
      <ModalWrapper />
      <AnimatePresence mode="wait">
        <Routes key={"routea"}>
          <>
            {UserRoutes(user?.id, user?.onboarding, isLoading, user?.role)}
            {AdminRoutes(user?.permissions, isLoading)}
            {PublicRoutes()}
            {NoAuthRoutes(user?.id, user?.onboarding, user?.permissions, user?.role)}
            {NotVerifiedRoutes(user?.id, user?.onboarding !== "complete")}
          </>
          {!isLoading && <Route key={"*"} path="*" element={<React.Suspense fallback={<FullScreenLoader />}><Permissionpage /></React.Suspense>} />}
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
