import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import getSubscription from "services/Subscriprion/getSubscription";
import forgot from "services/User/forgot";
import logout from "services/User/logout";
import refresh from "services/User/refresh";
import resendCode from "services/User/resendCode";
import signin from "services/User/signin";
import signup from "services/User/signup";
import update from "services/User/updatePassword";
import verify from "services/User/verify";
import { removeToken, setToken, getToken } from "utils/token";
import updateProfile from "services/User/updateProfile";
import googleLogin from "services/User/googleLogin";
import reduceLaunches from "services/User/reduceLaunches";
import { toast } from "react-hot-toast"
import updatePasswordFromProfile from "services/User/updatePasswordProfile";
import extensionInstalled from "services/User/extensionInstalled";
import { sendWhatsappOtp, validateWhatsappOtp } from "services/User/phoneVerification";
const defaultFetchOptions = {
  refetchOnMount: false, 
  refetchOnWindowFocus: false, 
  refetchOnReconnect: false
}

export const useExtensionInstallationUpdate = () => {
  const { mutate } = useMutation({
    mutationFn: extensionInstalled
  })
  return { updateExtensionStatus: mutate }
}

export const useUser = () => {
  const onSuccess = async () => {
    const customEvent = new CustomEvent('login-performed');
    window.dispatchEvent(customEvent);
  };

  const token = getToken();
  if (token) {
    const customEvent = new CustomEvent('login-performed')
    window.dispatchEvent(customEvent)
  }

  const { data, isFetching, isError, error } = useQuery(["refresh"], refresh, { onSuccess });
  return { user: data, isLoading: isFetching, isError, error };
};

export const useSignin = () => {

  const onSuccess = async (token: string) => {
    setToken(token);
    const customEvent = new CustomEvent('login-performed')
    window.dispatchEvent(customEvent)
  };

  const onError = (error: any) =>{
    toast.error(error?.response?.data?.message || "There was an error logging in")
  }

  const { mutateAsync, isLoading } = useMutation(signin, { onSuccess, onError });
  return { signin: mutateAsync, isLoading };
};

export const useRecoveryEmail = () => {
  const { data } = useQuery(["recovery-email"], async () => ({email: ''}))
  return { email: data?.email }
}

export const useForgot = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();


  const onError = (err: any) => {
    toast.error("Something went wrong");
  };

  const onSuccess = ({ email }: { email: string, error?: string }) => {
    toast.success(`Code was send successfully to ${email}`);
    queryClient.setQueryData(["recovery-email"], { email });
    navigate("/verifyEmail");
  };

  const { mutateAsync, isLoading } = useMutation(forgot, { onSuccess, onError });
  return { forgot: mutateAsync, isLoading };
};

export const useUpdatePassword = () => {
  const navigate = useNavigate();

  const onSuccess = (token:  string) => {
    setToken(token)
    toast.success("Password changed successfully")
    navigate("/signin");
  }

  const onError = () => {
    toast.error("There was error changing password")
  };

  const { mutateAsync, isLoading } = useMutation(update,{onSuccess, onError});
  return{ update: mutateAsync, isLoading }
}

export const useUpdatePasswordProfile = () => {
  const onSuccess = () => {
    toast.success("Password changed successfully")
  }

  const onError = (err: any) => {
    toast.error(err?.response?.data?.message || "Error While updating the password")
  };

  const {mutateAsync, isLoading} = useMutation(updatePasswordFromProfile,{onSuccess, onError});
  return{ updatePasswordFromProfile: mutateAsync, isLoading }
}

export const useUpdateProfile = () => {
  const client = useQueryClient()

  const onSuccess = () => {
    toast.success("Profile Updated Successfully")
    client.invalidateQueries(["refresh"]);
  }

  const onError = () => {
    toast.error("There was an error updating profile")
  };

  const { mutateAsync, isLoading } = useMutation(updateProfile,{onSuccess, onError});
  return{ updateProfile: mutateAsync, isLoading }
}

export const useSignup = () => {
  const client = useQueryClient();
  const navigate = useNavigate();

  const onSuccess = (token: string) => {
    setToken(token);
    client.invalidateQueries(["refresh"]);
    navigate('/user/verify')
  };

  const onError = (error: any) =>{
    toast.error(error?.response?.data?.message)
  }

  const { mutateAsync, isLoading } = useMutation(signup, { onSuccess, onError });
  return { signup: mutateAsync, isLoading };
};

export const useVerify = () => {
  const navigate = useNavigate();
  const client = useQueryClient();

  const onSuccess = (token: string) => {
    setToken(token)
    const customEvent = new CustomEvent('login-performed')
    window.dispatchEvent(customEvent)
    client.setQueryData(["refresh"], (state: any) => ({
      ...state,
      status: true,
      onboarding: "whatsapp"
    }));
    navigate('/user/dashboard')
  };

  const onError = (err: any) => {
    toast.error(err?.response?.data?.message || "Your account was not verified")
  };

  const onEmailSuccess = (token: string) => {
    setToken(token)
    client.setQueryData(["refresh"], (state: any) => ({
      ...state,
      status: true,
    }));
    navigate('/reset')
  };

  const onEmailError = (err: any) => {
    toast.error(err?.response?.data?.message || "Your account was not verified")
  };

  const onResendCode = () => toast.success("Code was send successfully")
  const onResendFailure = () => toast.error("We couldn't send you a code")

  const { mutateAsync, isLoading: isVerifying } = useMutation(verify, {
    onSuccess,
    onError,
  });

  const { mutateAsync: resend, isLoading: isResending } = useMutation(
    resendCode,
    { onSuccess: onResendCode, onError: onResendFailure }
  );
   const { mutateAsync: forgetCode, isLoading: isResend } = useMutation(verify, {
    onSuccess: onEmailSuccess,
    onError: onEmailError,
  });

  return {
    verify: mutateAsync, resendCode: resend,  forgetCode , isResend , isVerifying, isResending,
  };
};

export const usePhoneVerify = () => {
  const navigate = useNavigate();
  const client = useQueryClient();

  const onSuccess = ()=>{
    client.invalidateQueries({
      predicate: ({ queryKey }) => queryKey[0] === "refresh"
    })
    toast.success('Phone number verified successfully')
  }
  const onError = ()=> toast.error('Invalid code entered')
 const {mutateAsync: sendWhatsappOtpMutation } = useMutation(sendWhatsappOtp)

 const {mutateAsync: validateWhatsappOtpMutation} = useMutation(validateWhatsappOtp, {onSuccess, onError})
  

  return {
    sendWhatsappOtp: sendWhatsappOtp,
    validateWhatsappOtp: validateWhatsappOtpMutation,
  };
};

export const useGoogleLoginCustom = () => {
  const client = useQueryClient();
  const navigate = useNavigate()

  const onSuccess = (token: string) => {
    setToken(token);
    const customEvent = new CustomEvent('login-performed')
    window.dispatchEvent(customEvent)
    navigate('/user/dashboard')
    client.invalidateQueries(["refresh"]);
  };

  const { mutateAsync } = useMutation(googleLogin, { onSuccess });
  return { loginGoogle: mutateAsync };
};


export const useLogout = () => {
  const client = useQueryClient();
  const naigate = useNavigate()

  const onSuccess = () => {
    const customEvent = new CustomEvent('logout-performed');
    window.dispatchEvent(customEvent)
    removeToken();
    client.setQueryData(["refresh"], null)
    client.clear()
    naigate("/signin")
  };

  const { mutateAsync, isLoading } = useMutation(logout, { onSuccess });
  return { logout: mutateAsync, isLoading };
};

export const useSubscription = () => {
    const { user } = useUser()
    const { data, isLoading } = useQuery(["getSubscription"], getSubscription, { ...defaultFetchOptions, enabled: !!user?.id, refetchOnWindowFocus: true})
    return { subscription: data, isGettingSubscription: isLoading }
}

export const useReduceLaunches = () => {
  const client = useQueryClient()

  const onSuccess = () => client.invalidateQueries(["refresh"])

  const { mutateAsync, isLoading } = useMutation(reduceLaunches, { onSuccess })
  return { reduceLaunches: mutateAsync, isReducingLaunches: isLoading }
}
