import React from "react";
import { Button, Image } from "antd";
import "./styles.scss";
import StarRatingIcon from "assets/icons/StarRatingIcon";
import { IProductDiscovery } from "types/services";
import { formatRelativeTime } from "utils/helper";

interface IProductCard {
    product: IProductDiscovery
    handleQuickView: (itemId: number) => void
    productUrl: string
}
const ProductCard: React.FC<IProductCard> = ({product, handleQuickView, productUrl }) => {
  const handleRedirect = () => {
    window.open(productUrl, "_blank")
  }
  return (
    <div className="product-card">
      <div className="image-wrapper">
        <Image
          src={product.image}
          width={"100%"}
          height={"100%"}
          alt={"image"}
          className="product-image"
          preview={false}
        />
      </div>
      <div className="product-details">
        <div>
          <h5 className="product-title">{product.name}</h5>
        </div>
        <div className="price-container">
          <div className="saling-price">
            <span>Selling Price:</span>
            {product?.price?.toLocaleString()}
          </div>
          <div className="stat-item">
              <StarRatingIcon />
              {Math.round(Number(product.rating) * 10) / 10} ({product.reviews})
          </div>
        </div>
        
        <div className="sold-units">
          Sold Units:
        </div>
        <div className="stats-container">
          <div className="stat-item">
            <span className="sale-count">{product.weeklySale}</span>
            <span>Weekly</span>
          </div>
          <div className="stat-item">
            <span className="sale-count">{product.monthlySale}</span>
            <span>Monthly</span>
          </div>
          <div className="stat-item">
            <span className="sale-count">{product.allTimeSale}</span>
            <span>All Time</span>
          </div>
        </div>
        <div className="product-quick-view-wrap">
          <div className="action-btns">
            <Button className="primary-btn reset-btn" onClick={() => handleQuickView(product.itemId)}>
              Quick View
            </Button>
            <Button className="secondary-btn reset-btn" onClick={() => handleRedirect()}>
              Product Details
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
