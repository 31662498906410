import { useRef, useState } from "react";
import { Center, Flex, Image } from "@chakra-ui/react";
import EmptyCircle from "assets/icons/EmptyCircle";
import FilledCircle from "assets/icons/FilledCircle";
import One from "assets/img/store-insights-1.png";
import Two from "assets/img/product-research-1.png";
import Three from "assets/img/market-research-1.png"
import Four from "assets/img/product-details-1.png"

const AuthCarousel = () => {
  const [active, setActive] = useState(1);
  const imageOne = useRef<HTMLDivElement | null>(null);
  const imageTwo = useRef<HTMLDivElement | null>(null);
  const imageThree = useRef<HTMLDivElement | null>(null);
  const imageFour = useRef<HTMLDivElement | null>(null);


  const handleChange = (item: number) => {
    if (item === active) return;
    if (item === 1) {
      setActive(1);
      imageOne.current && (imageOne.current.style.opacity = "1");
      imageTwo.current && (imageTwo.current.style.opacity = "0");
      imageThree.current && (imageThree.current.style.opacity = "0");
      imageFour.current && (imageFour.current.style.opacity = "0");
    } else if (item === 2) {
      setActive(2);
      imageOne.current && (imageOne.current.style.opacity = "0");
      imageThree.current && (imageThree.current.style.opacity = "0");
      imageTwo.current && (imageTwo.current.style.opacity = "1");
      imageFour.current && (imageFour.current.style.opacity = "0");
    } else if (item === 3) {
      setActive(3);
      imageOne.current && (imageOne.current.style.opacity = "0");
      imageTwo.current && (imageTwo.current.style.opacity = "0");
      imageThree.current && (imageThree.current.style.opacity = "1");
      imageFour.current && (imageFour.current.style.opacity = "0");

    } else {
      setActive(4);
      imageOne.current && (imageOne.current.style.opacity = "0");
      imageTwo.current && (imageTwo.current.style.opacity = "0");
      imageThree.current && (imageThree.current.style.opacity = "0");
      imageFour.current && (imageFour.current.style.opacity = "1");

    }
  };

  return (
    <Center
      bgColor="#FAFAFA"
      flex={1}
      w="50%"
      overflow="hidden"
      flexDir="column"
      position="relative">
      <Flex
        ref={imageOne}
        transition="0.3s all ease-in-out"
        opacity={1}
        width="100%"
        height="100%"
        pos="absolute">
        <div className="background-img" style={{ backgroundImage: `url(${One})`, width: "100%", height: "100%", backgroundSize: "cover"}}></div>
      </Flex>
      <Flex
        opacity={0}
        ref={imageTwo}
        width="100%"
        height="100%"
        transition="0.3s all ease-in-out"
        pos="absolute"
      >
        <div className="background-img" style={{ backgroundImage: `url(${Two})`, width: "100%", height: "100%", backgroundSize: "cover"}}></div>
      </Flex>
      <Flex
        opacity={0}
        ref={imageThree}
        transition="0.3s all ease-in-out"
        width="100%"
        height="100%"
        pos="absolute"
      >
        <div className="background-img" style={{ backgroundImage: `url(${Three})`, width: "100%", height: "100%", backgroundSize: "cover"}}></div>
      </Flex>
      <Flex
        opacity={0}
        ref={imageFour}
        transition="0.3s all ease-in-out"
        width="100%"
        height="100%"
        pos="absolute"
      >
        <div className="background-img" style={{ backgroundImage: `url(${Four})`, width: "100%", height: "100%", backgroundSize: "cover"}}></div>
      </Flex>
      <Center flexDir="row" gap="10px" pos="absolute" bottom="40px" w="100%">
        {[1, 2, 3, 4].map((item) =>
          item === active ? (
            <Center onClick={() => handleChange(item)} cursor="pointer">
              <FilledCircle key={item} />
            </Center>
          ) : (
            <Center onClick={() => handleChange(item)} cursor="pointer">
              <EmptyCircle key={item} />
            </Center>
          )
        )}
      </Center>
    </Center>
  );
};

export default AuthCarousel;