import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import {
  AiOutlineYoutube,
  AiOutlineWhatsApp,
  AiOutlineChrome,
} from "react-icons/ai";
import { CiFacebook, CiInstagram, CiLinkedin } from "react-icons/ci";

import WhiteLogo from "assets/svgs/whiteLogo.svg";
import {
  YOUTUBE_LINK,
  FACEBOOK_LINK,
  LINKEDIN_LINK,
  WHATSAPP_LINK,
  CHROME_EXTENSION_LINK,
  Routes,
  INSTAGRAM_LINK,
} from "../../../constants/routes";
import NewsLetter from "./NewsLetter/NewsLetter";

const socialLinks = [
  {
    id: 1,
    to: YOUTUBE_LINK,
    icon: <AiOutlineYoutube color="#ffffff" />,
  },
  {
    id: 2,
    to: FACEBOOK_LINK,
    icon: <CiFacebook color="#ffffff" />,
  },
  {
    id: 3,
    to: INSTAGRAM_LINK,
    icon: <CiInstagram color="#ffffff" />,
  },
  {
    id: 4,
    to: LINKEDIN_LINK,
    icon: <CiLinkedin color="#ffffff" />,
  },
  {
    id: 5,
    to: WHATSAPP_LINK,
    icon: <AiOutlineWhatsApp color="#ffffff" />,
  },
  {
    id: 6,
    to: CHROME_EXTENSION_LINK,
    icon: <AiOutlineChrome color="#ffffff" />,
  },
];

const linksData = [
  { to: "/", name: "Home" },
  { to: Routes.BLOGS, name: "Blogs" },
  { to: Routes.FAQS, name: "FAQs" },
];

const otherLinks = [
  { to: Routes.PRIVACY_POLICY, name: "Privacy Policy" },
  { to: Routes.TERMS, name: "Terms & Condition" },
  { to: Routes.CONTACT, name: "Contact Us" },
];

const Footer: React.FC = () => {
  const date = new Date();

  return (
    <div className="footer-container">
      <NewsLetter />
      <div className="footer-content">
        <div className="footer-main">
          <div className="footer-left">
            <img src={WhiteLogo} alt="white-logo" className="footer-logo" />
            <p className="footer-text">
              SellerDash (Private) Limited is a powerful product hunting tool
              for Daraz & Lazada. With a wide array of features, SellerDash
              simplifies your product hunting operations, providing you with the
              tools you need to succeed in a highly competitive marketplace.
            </p>
            <div className="footer-social-links">
              {socialLinks.map((link) => (
                <a
                  key={link.id}
                  href={link.to}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  {link.icon}
                </a>
              ))}
            </div>
          </div>
          <div className="footer-right">
            <div className="footer-links-section">
              <h3 className="footer-links-title">Quick Links</h3>
              <ul className="footer-links">
                {linksData.map((data) => (
                  <li key={data.name}>
                    <Link to={data.to} className="footer-link">
                      {data.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-links-section">
              <h3 className="footer-links-title">Other Links</h3>
              <ul className="footer-links">
                {otherLinks.map((data) => (
                  <li key={data.name}>
                    <Link to={data.to} className="footer-link">
                      {data.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <p className="footer-copy">
          {date.getFullYear()} © SellerDash (Private) Limited - All Rights
          Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
