import useScrollToTop from "hooks/useScrollToTop";
import { Helmet } from "react-helmet";
import { Center, Flex, Image, Text, useMediaQuery, Box } from "@chakra-ui/react";
import * as styles from "./style";
import { NavLink } from "react-router-dom";
import AuthCarousel from "components/AuthCarousel/AuthCarousel";
import Logo from "assets/svgs/nav-icon.svg";

interface Props {
  children: React.ReactNode;
  title: string;
  page: string;
  pageWidth?: any;
}

const AuthLayout: React.FC<Props> = ({
  children,
  title,
  page,
  pageWidth = "25%",
}) => {
  useScrollToTop();
  const [isLargerThan1280] = useMediaQuery("(min-width: 1000px)");
  const [isLargerThan500] = useMediaQuery("(min-width: 560px)");

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Find sales of sellers and track performance of their best selling products with sellerdash which is a best daraz product hunting tool." />
        <meta property="og:url" content="https://sellerdash.pk" />
        <meta property="og:locale" content="en" />
        <meta property="og:site_name" content="Sellerdash" />
        <meta property="og:description" content="Find sales of sellers and track performance of their best selling products with sellerdash which is a best daraz product hunting tool." />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta property="og:image" content={Logo} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta property="og:image:type" content="image/png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:src" content={Logo} />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="600" />

      </Helmet>
      <Flex
        {...styles.Wrapper}
        style={{
          background:
            "linear-gradient(to bottom,  #185ADB 0%,#185ADB 35%,#fff 35%,#fff 100%)",
        }}
      >
        <Center {...styles.MainWrapper}>
          <Flex
            w={isLargerThan1280 ? `${page === "Signup" ? "1300px" :  "1114px"}` : "89%"}
            {...styles.InnerWrapper}
          >
            <Flex
              {...styles.ContentWrapper}
              w={isLargerThan500 ? "100%" : "100%"}
            >
              <Flex justify="center">
                <NavLink to="/">
                  <Image src={Logo} alt="logo" />
                </NavLink>
              </Flex>
              <Flex bg="#185ADB" w={isLargerThan500 ? pageWidth : "50%"} py={2} mt="20px" justify="center">
                <Text {...styles.MainText}>{page}</Text>
              </Flex>
              <Box w={isLargerThan500 ? "440px" : "100%"} m="auto" p="20px" {...styles.FieldWrapper}>{children}</Box>
            </Flex>
            <Flex w={isLargerThan1280 ? "100%" : "auto"}>{isLargerThan1280 && <AuthCarousel />}</Flex>
          </Flex>
        </Center>
      </Flex>
    </>
  );
};

export default AuthLayout;
