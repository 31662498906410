import React from 'react'

const EmptyCircle = ({ width = 28, height = 28 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="13.25" stroke="#185ADB" stroke-width="1.5"/>
    </svg>
  )
}

export default EmptyCircle