import { PERMISSIONS } from 'enum/permissions';
import { ROLES } from 'enum/roles';
import React from 'react'
import {  Route, Navigate } from 'react-router-dom'
import FullScreenLoader from './FullScreenLoader';

const Signin = React.lazy(() => import("pages/Auth/Signin/Signin"))
const Signup = React.lazy(() => import("pages/Auth/Signup/Signup"))

const NoAuthRoutes = (userId?: number, onboarding?: string, userPermission?: PERMISSIONS[] | undefined, userRole?: string ) => {

    const permissionToRouteMap = {
      [PERMISSIONS.ACCESS_ADMIN_DASHBOARD]: ["dashboard", "profile"],
      [PERMISSIONS.ACCESS_ADMIN_REPORTS]: ["reports"],
      [PERMISSIONS.ACCESS_ADMIN_COUPONS]: ["coupons"],
      [PERMISSIONS.ACCESS_ADMIN_USER_MANAGEMENT]: ["users"],
      [PERMISSIONS.ACCESS_ADMIN_PRODUCTS]: ["products"],
      [PERMISSIONS.ACCESS_ADMIN_CUSTOMERS]: ["customers"],
      [PERMISSIONS.ACCESS_ADMIN_SUBSCRIPTIONS]: ["subsciptions"],
      [PERMISSIONS.ACCESS_ADMIN_ROLES]: ["roles"],
      [PERMISSIONS.ACCESS_ADMIN_MONTHLY_SALES]: ["sales"],
      [PERMISSIONS.ACCESS_ADMIN_PAYMENT_HISTORY]: ["payment-history"],
      [PERMISSIONS.ACCESS_ADMIN_BLOG_MANAGEMENT]: ["blogs", "blogs/:id", "add-blog"],
      [PERMISSIONS.ACCESS_ADMIN_FAQ_MANAGEMENT]: ["faq"],
    }


    // if(userId && !status) return [
    //   <> 
    //     <Route key={"/signin"} path="/signin" element={<Navigate to="/verify" />} />
    //     <Route key={"signup"} path="/signup" element={<Navigate to="/verify" />} />
    //    </>
    // ]

    if(userId && onboarding !== "complete" && userRole === ROLES.CUSTOMER) return [
      <> 
        <Route key={"/signin"} path="/signin" element={<Navigate to="/onboarding" />} />
        <Route key={"signup"} path="/signup" element={<Navigate to="/onboarding" />} />
       </>
    ]
    
    
    if(userId) return (
      <>
        <Route key={"/user/signin"} path="/signin" element={<Navigate to={userRole=== ROLES.CUSTOMER ? '/user/dashboard' : `/admin/${permissionToRouteMap[userPermission?.find(permission => permission.includes("ACCESS_ADMIN")) as keyof typeof permissionToRouteMap]?.[0]}`} />} />
        <Route key={"user/signup"} path="/signup" element={<Navigate to={userRole === ROLES.CUSTOMER ? '/user/dashboard' : `/admin/${permissionToRouteMap[userPermission?.find(permission => permission.includes("ACCESS_ADMIN")) as keyof typeof permissionToRouteMap]?.[0]}`} />} />
      </>
    )

    return [
      <>
          <Route key={"/user/signin2"} path="signin" element={<React.Suspense fallback={<FullScreenLoader />}><Signin /></React.Suspense>} />
          <Route key={"/user/signup1"} path="signup" element={<React.Suspense fallback={<FullScreenLoader />}><Signup /></React.Suspense>} />
      </>
    ]
}

export default NoAuthRoutes