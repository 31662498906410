import { useUser } from "hooks/user"

import Marketplace from "./components/Marketplace"
import Whatsapp from "./components/Whatsapp"
import Referrer from "./components/Referrer"
import Email from "./components/Email"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Onboarding = () => {
    const { user } = useUser()
    const navigate = useNavigate()
    useEffect(() => {
        if(!user)
            navigate("signin")
    }, [user])
    const map: Record<string, JSX.Element> = {
        email: <Email />,
        whatsapp: <Whatsapp />,
        marketplace: <Marketplace />,
        referrer: <Referrer />,
    }
    return (
        <div className="w-full h-screen overflow-hidden">
            {map[user?.onboarding || "whatsapp"]}
        </div>
    )
}

export default Onboarding
