import React from 'react'
import { Route, Navigate } from "react-router-dom"
import FullScreenLoader from './FullScreenLoader'
import Onboarding from 'pages/Auth/Onboarding/Onboarding'

const Verify = React.lazy(() => import("pages/Auth/Verify/Verify"))

const NotVerifiedRoutes = (userId?: number, onboarding?: boolean) => {
  if(userId && onboarding)
    return [
          <Route path="/onboarding" element={<Onboarding />} />
    ]

  return [
        <Route path="/onboarding" element={<Navigate to="/signin" />} />
  ]
}

export default NotVerifiedRoutes