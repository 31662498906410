import AuthLayout from "layouts/AuthLayout"
import { BarePhoneVerificationComponent } from "modals/PhoneNumberModal/PhonePopup"

const Whatsapp = () => {
    return (
        <AuthLayout
            title="Verify WhatsApp - Sellerdash"
            page="WhatsApp Verify"
            pageWidth="40%"
            >
            <BarePhoneVerificationComponent />
        </AuthLayout>
    )
}

export default Whatsapp
